import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src311988156/src/mad-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Image } from '@madup-inc/mad-design';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "image"
    }}>{`Image`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Image} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Image } from './Icon'

export default () => {
  return (
    <Image src='https://some-image-location.jpg' />
  )
}
`}</code></pre>
    <Playground __position={1} __code={'<div style={{ width: 300 }}>\n  <Image src=\"https://lever.me/statics/image/knowHow/9-1.jpg\" />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Image,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        width: 300
      }}>
    <Image src='https://lever.me/statics/image/knowHow/9-1.jpg' mdxType="Image" />
  </div>
    </Playground>
    <br />
    <h2 {...{
      "id": "different-preview-message"
    }}>{`Different preview message`}</h2>
    <Playground __position={3} __code={'<div style={{ width: 300 }}>\n  <Image\n    src=\"https://lever.me/statics/image/knowHow/11-1.jpg\"\n    preview={{ message: <span>view more</span> }}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Image,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        width: 300
      }}>
    <Image src='https://lever.me/statics/image/knowHow/11-1.jpg' preview={{
          message: <span>view more</span>
        }} mdxType="Image" />
  </div>
    </Playground>
    <h2 {...{
      "id": "different-preview-image"
    }}>{`Different preview image`}</h2>
    <Playground __position={4} __code={'<div style={{ width: 300 }}>\n  <Image\n    src=\"https://lever.me/statics/image/knowHow/11-1.jpg\"\n    preview={{ src: \'https://lever.me/statics/image/knowHow/9-1.jpg\' }}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Image,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        width: 300
      }}>
    <Image src='https://lever.me/statics/image/knowHow/11-1.jpg' preview={{
          src: 'https://lever.me/statics/image/knowHow/9-1.jpg'
        }} mdxType="Image" />
  </div>
    </Playground>
    <h2 {...{
      "id": "onclose-event"
    }}>{`onClose event`}</h2>
    <Playground __position={5} __code={'<div style={{ width: 300 }}>\n  <Image\n    src=\"https://lever.me/statics/image/knowHow/11-1.jpg\"\n    preview={{ onClose: () => alert(\'close\') }}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Image,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        width: 300
      }}>
    <Image src='https://lever.me/statics/image/knowHow/11-1.jpg' preview={{
          onClose: () => alert('close')
        }} mdxType="Image" />
  </div>
    </Playground>
    <h2 {...{
      "id": "do-not-use-preview"
    }}>{`do not use preview`}</h2>
    <Playground __position={6} __code={'<div style={{ width: 300 }}>\n  <Image\n    src=\"https://lever.me/statics/image/knowHow/11-1.jpg\"\n    preview={false}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Image,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        width: 300
      }}>
    <Image src='https://lever.me/statics/image/knowHow/11-1.jpg' preview={false} mdxType="Image" />
  </div>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      